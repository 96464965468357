import { IRewiew } from './../../types/IRewiew'

import item1 from '../images/rewiews/item1.png'
import item2 from '../images/rewiews/item2.png'
import item3 from '../images/rewiews/item3.png'
import item4 from '../images/rewiews/item4.png'

export const rewiews: IRewiew[] = [
	{
		id: 0,
		name: 'Грановский Аркадий',
		role: 'Ведущий специалист информационной безопасности',
		text: 'Важным критерием выбора платформы было удобство в использовании. Нам хотелось, чтобы она была максимально простой в настройке. В результате мы остановились на Secure-T Awareness Platform.',
		image: item1,
		color: '#E8E2FF',
		shadow: '0px 6px 16px rgba(74, 68, 99, 0.16)',
	},
	{
		id: 1,
		name: 'Митюшкин Кирилл',
		role: 'IT директор',
		text: 'Обучающие курсы помогают нашим сотрудниками узнать о разных способах обмана, а регулярное дополнение материалов позволяет быть в курсе новых методов злоумышленников.',
		image: item2,
		color: '#EAEAEA',
		shadow: '0px 6px 16px rgba(96, 96, 96, 0.16)',
	},
	{
		id: 2,
		name: 'Юдин Дмитрий',
		role: 'IT директор',
		text: 'Мы пользуемся платформой уже 2 года, за это время сотрудники стали на порядок внимательнее относиться ко всей внешней корреспонденции.',
		image: item3,
		color: '#E3F0FF',
		shadow: '0px 6px 16px rgba(92, 101, 111, 0.16',
	},
	{
		id: 3,
		name: 'Качалин Игорь',
		role: 'IT директор',
		text: 'Платформа Secure-T Awareness Platform зарекомендовала себя как очень удобный, гибкий и надежный инструмент для проверки сотрудников на уязвимость к фишинговым атакам.',
		image: item4,
		color: '#FFE2E7',
		shadow: '0px 6px 16px rgba(102, 88, 91, 0.16)',
	},
]
