import classNamesBind from 'classnames/bind'
import { Container } from '../../components/common'

import styles from './TextPage.module.scss'

const cx = classNamesBind.bind(styles)

export const privacyText = (
	<Container className={cx('container')}>
		<div className={cx('title')}>Политика в отношении обработки персональных данных </div>
		<div className={cx('blocks')}>
			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>1. Общие положения</div>
				<div className={cx('blockText', 'text')}>
					Настоящая политика обработки персональных данных составлена в соответствии с требованиями
					Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок
					обработки персональных данных и меры по обеспечению безопасности персональных данных,
					предпринимаемые ООО "Секьюр-Ти" (далее – Оператор).
				</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>1.1</span> Оператор ставит своей важнейшей целью и условием осуществления своей
						деятельности соблюдение прав и свобод человека и гражданина при обработке его
						персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную
						и семейную тайну.
					</div>
					<div className={cx('rule', 'text')}>
						<span>1.2</span> Настоящая политика Оператора в отношении обработки персональных данных
						(далее – Политика) применяется ко всей информации, которую Оператор может получить о
						посетителях веб-сайта <a href='https://secure-t.ru'>https://secure-t.ru</a>.
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>2. Основные понятия, используемые в Политике</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>2.1</span> Автоматизированная обработка персональных данных – обработка
						персональных данных с помощью средств вычислительной техники;
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.2</span> Блокирование персональных данных – временное прекращение обработки
						персональных данных (за исключением случаев, если обработка необходима для уточнения
						персональных данных);
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.3</span> Веб-сайт – совокупность графических и информационных материалов, а
						также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по
						сетевому адресу <a href='https://secure-t.ru'>https://secure-t.ru</a>;
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.4</span> Информационная система персональных данных — совокупность содержащихся
						в базах данных персональных данных, и обеспечивающих их обработку информационных
						технологий и технических средств;
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.5</span> Обезличивание персональных данных — действия, в результате которых
						невозможно определить без использования дополнительной информации принадлежность
						персональных данных конкретному Пользователю или иному субъекту персональных данных
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.6</span> Обработка персональных данных – любое действие (операция) или
						совокупность действий (операций), совершаемых с использованием средств автоматизации или
						без использования таких средств с персональными данными, включая сбор, запись,
						систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
						использование, передачу (распространение, предоставление, доступ), обезличивание,
						блокирование, удаление, уничтожение персональных данных;
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.7</span> Оператор – государственный орган, муниципальный орган, юридическое или
						физическое лицо, самостоятельно или совместно с другими лицами организующие и (или)
						осуществляющие обработку персональных данных, а также определяющие цели обработки
						персональных данных, состав персональных данных, подлежащих обработке, действия
						(операции), совершаемые с персональными данными;
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.8</span> Персональные данные – любая информация, относящаяся прямо или косвенно
						к определенному или определяемому Пользователю веб-сайта{' '}
						<a href='https://secure-t.ru'>https://secure-t.ru</a>;
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.9</span> Пользователь – любой посетитель веб-сайта{' '}
						<a href='https://secure-t.ru'>https://secure-t.ru</a>;
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.10</span> Предоставление персональных данных – действия, направленные на
						раскрытие персональных данных определенному лицу или определенному кругу лиц;
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.11</span> Распространение персональных данных – любые действия, направленные на
						раскрытие персональных данных неопределенному кругу лиц (передача персональных данных)
						или на ознакомление с персональными данными неограниченного круга лиц, в том числе
						обнародование персональных данных в средствах массовой информации, размещение в
						информационно-телекоммуникационных сетях или предоставление доступа к персональным
						данным каким-либо иным способом;
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.12</span> Трансграничная передача персональных данных – передача персональных
						данных на территорию иностранного государства органу власти иностранного государства,
						иностранному физическому или иностранному юридическому лицу;
					</div>
					<div className={cx('rule', 'text')}>
						<span>2.13</span> Уничтожение персональных данных – любые действия, в результате которых
						персональные данные уничтожаются безвозвратно с невозможностью дальнейшего
						восстановления содержания персональных данных в информационной системе персональных
						данных и (или) уничтожаются материальные носители персональных данных.
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>
					3. Оператор может обрабатывать следующие персональные данные Пользователя
				</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>3.1</span> Фамилия, имя, отчество;
					</div>
					<div className={cx('rule', 'text')}>
						<span>3.2</span> Электронный адрес;
					</div>
					<div className={cx('rule', 'text')}>
						<span>3.3</span> Фотографии;
					</div>
					<div className={cx('rule', 'text')}>
						<span>3.4</span> Место работы, отдел, должность;
					</div>
					<div className={cx('rule', 'text')}>
						<span>3.5</span> Прогресс обучения;
					</div>
					<div className={cx('rule', 'text')}>
						<span>3.6</span> Действия совершенные при переходе по ссылкам отправленных со стороны
						платформы;
					</div>
					<div className={cx('rule', 'text')}>
						<span>3.7</span> Также на сайте происходит сбор и обработка обезличенных данных о
						посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс
						Метрика и Гугл Аналитика и других).
					</div>
					<div className={cx('rule', 'text')}>
						<span>3.8</span> Вышеперечисленные данные далее по тексту Политики объединены общим
						понятием Персональные данные.
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>4. Цели обработки персональных данных</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>4.1</span> Цель обработки персональных данных Пользователя — информирование
						Пользователя посредством отправки электронных писем; предоставление доступа Пользователю
						к сервисам, информации и/или материалам, содержащимся на веб-сайте.
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.2</span> Также Оператор имеет право направлять Пользователю уведомления о новых
						продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда
						может отказаться от получения информационных сообщений, направив Оператору письмо на
						адрес электронной почты <a href='mailto:info@secure-t.ru'>info@secure-t.ru</a> с
						пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».
					</div>
					<div className={cx('rule', 'text')}>
						<span>4.3</span> Обезличенные данные Пользователей, собираемые с помощью сервисов
						интернет-статистики, служат для сбора информации о действиях Пользователей на сайте,
						улучшения качества сайта и его содержания.
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>
					5. Правовые основания обработки персональных данных
				</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>5.1</span> Оператор обрабатывает персональные данные Пользователя только в случае
						их заполнения и/или отправки Пользователем самостоятельно через специальные формы,
						расположенные на сайте <a href='https://secure-t.ru'>https://secure-t.ru</a>. Заполняя
						соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь
						выражает свое согласие с данной Политикой.
					</div>
					<div className={cx('rule', 'text')}>
						<span>5.2</span> Оператор обрабатывает обезличенные данные о Пользователе в случае, если
						это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и
						использование технологии JavaScript).
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>
					6. Порядок сбора, хранения, передачи и других видов обработки персональных данных
				</div>
				<div className={cx('blockText', 'text')}>
					Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем
					реализации правовых, организационных и технических мер, необходимых для выполнения в
					полном объеме требований действующего законодательства в области защиты персональных
					данных.
				</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>6.1</span> Оператор обеспечивает сохранность персональных данных и принимает все
						возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
					</div>
					<div className={cx('rule', 'text')}>
						<span>6.2</span> Персональные данные Пользователя никогда, ни при каких условиях не
						будут переданы третьим лицам, за исключением случаев, связанных с исполнением
						действующего законодательства.
					</div>
					<div className={cx('rule', 'text')}>
						<span>6.3</span> В случае выявления неточностей в персональных данных, Пользователь
						может актуализировать их самостоятельно, путем направления Оператору уведомление на
						адрес электронной почты Оператора <a href='mailto:info@secure-t.ru'>info@secure-t.ru</a>{' '}
						с пометкой «Актуализация персональных данных».
					</div>
					<div className={cx('rule', 'text')}>
						<span>6.4</span> Срок обработки персональных данных является неограниченным.
						Пользователь может в любой момент отозвать свое согласие на обработку персональных
						данных, направив Оператору уведомление посредством электронной почты на электронный
						адрес Оператора <a href='mailto:info@secure-t.ru'>info@secure-t.ru</a> с пометкой «Отзыв
						согласия на обработку персональных данных».
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>
					7. Трансграничная передача персональных данных
				</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>7.1</span> Оператор до начала осуществления трансграничной передачи персональных
						данных обязан убедиться в том, что иностранным государством, на территорию которого
						предполагается осуществлять передачу персональных данных, обеспечивается надежная защита
						прав субъектов персональных данных.
					</div>
					<div className={cx('rule', 'text')}>
						<span>7.2</span> Трансграничная передача персональных данных на территории иностранных
						государств, не отвечающих вышеуказанным требованиям, может осуществляться только в
						случае наличия согласия в письменной форме субъекта персональных данных на
						трансграничную передачу его персональных данных и/или исполнения договора, стороной
						которого является субъект персональных данных.
					</div>
				</div>
			</div>

			<div className={cx('block')}>
				<div className={cx('blockTitle', 'text')}>8. Заключительные положения</div>
				<div className={cx('rules')}>
					<div className={cx('rule', 'text')}>
						<span>8.1</span> Пользователь может получить любые разъяснения по интересующим вопросам,
						касающимся обработки его персональных данных, обратившись к Оператору с помощью
						электронной почты <a href='mailto:info@secure-t.ru'>info@secure-t.ru</a>.
					</div>
					<div className={cx('rule', 'text')}>
						<span>8.2</span> В данном документе будут отражены любые изменения политики обработки
						персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.
					</div>
					<div className={cx('rule', 'text')}>
						<span>8.3</span> Актуальная версия Политики в свободном доступе расположена в сети
						Интернет по адресу <a href='https://secure-t.ru/privacy'>https://secure-t.ru/privacy</a>
						.{' '}
					</div>
				</div>
			</div>
		</div>
	</Container>
)
