import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { privacyText } from './pages/TextPage/privacy'

import { MainLayout } from './components/layout/MainLayout'
import {
	AboutPage,
	MainPage,
	NewsItemPage,
	NotFoundPage,
	TextPage,
	VacancyItemPage,
	VacancyPage,
} from './pages'

const App: FC = () => {
	return (
		<Routes>
			<Route
				path='/'
				element={<MainLayout />}
			>
				<Route
					index
					path='/'
					element={<MainPage />}
				/>
				<Route
					path='/vacancy'
					element={<VacancyPage />}
				/>
				<Route
					path='/vacancy/:id'
					element={<VacancyItemPage />}
				/>
				<Route
					path='/about'
					element={<AboutPage />}
				/>
				<Route
					path='/about'
					element={<AboutPage />}
				/>
				<Route
					path='/privacy'
					element={<TextPage text={privacyText} />}
				/>
				<Route
					path='/news/:id'
					element={<NewsItemPage />}
				/>
				<Route
					path='*'
					element={<NotFoundPage />}
				/>
			</Route>
		</Routes>
	)
}

export default App
