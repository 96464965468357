import { FC, useContext, useRef } from 'react'
import classNamesBind from 'classnames/bind'
import InputMask from 'react-input-mask'

import { ModalsProps } from './Modals.d'

import styles from './Modals.module.scss'
import { Link } from 'react-router-dom'
import { ModalContext } from '../../../contexts'
import { useContact } from '../../../hooks'

const cx = classNamesBind.bind(styles)

const Modals: FC<ModalsProps.Props> = (props) => {
	const { isShow, type = '' } = props

	const { closeModal } = useContext(ModalContext)
	const wrapper = useRef<HTMLDivElement>(null)

	const { email, name, phone, setEmail, setName, setPhone, submit, loading, status, errors } =
		useContact()

	const handleSubmit = (event: any) => {
		event.preventDefault()

		submit(type ? type : '')
	}

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		if (wrapper.current && wrapper.current === event.target) {
			closeModal()
		}
	}

	return (
		<div
			ref={wrapper}
			className={cx('wrapper', `${isShow ? 'active' : ''}`)}
			onClick={(event) => handleClick(event)}
		>
			<div className={cx('form')}>
				<svg
					width='42'
					className={cx('close')}
					height='42'
					viewBox='0 0 42 42'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					onClick={() => closeModal()}
				>
					<rect
						x='9.05859'
						width='35'
						height='35'
						rx='12'
						transform='rotate(15 9.05859 0)'
						fill='#323037'
					/>
					<rect
						x='13'
						y='15.1213'
						width='3'
						height='19'
						transform='rotate(-45 13 15.1213)'
						fill='white'
					/>
					<rect
						x='15.1211'
						y='28.5563'
						width='3'
						height='19'
						transform='rotate(-135 15.1211 28.5563)'
						fill='white'
					/>
				</svg>
				<div className={cx('title')}>Получите демо</div>
				<input
					type='text'
					placeholder='Имя'
					value={name}
					onChange={(e) => setName(e.target.value)}
					className={cx(`${errors.includes('name') ? 'error' : ''}`)}
				/>
				<input
					type='text'
					placeholder='Почта'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					className={cx(`${errors.includes('email') ? 'error' : ''}`)}
				/>
				<InputMask
					type='text'
					placeholder='Телефон'
					mask='+7 (999) 999-99-99'
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					className={cx(`${errors.includes('phone') ? 'error' : ''}`)}
				/>
				<div className={cx('agree')}>
					Нажимая отправить, вы даете согласие на обработку персональных данных и соглашаетесь с{' '}
					<Link
						to='/privacy'
						target='_blank'
						// onClick={() => closeModal()}
					>
						политикой конфиденциальности.
					</Link>
				</div>
				<button onClick={handleSubmit}>
					{loading
						? 'ЗАГРУЗКА...'
						: status === 'ok'
						? 'Успешно'
						: status === 'error'
						? 'Ошибка'
						: 'Отправить'}
				</button>
			</div>
		</div>
	)
}

export default Modals
