import { IPartner } from './../../types/IPartners'

import partnersDown1 from '../images/partners/down/item1.png'
import partnersDown2 from '../images/partners/down/item2.png'
import partnersDown3 from '../images/partners/down/item3.png'
import partnersDown4 from '../images/partners/down/item4.png'
import partnersDown7 from '../images/partners/down/item7.png'

import a1 from '../images/partners/down/A1_red_logo.png'
import itprotect from '../images/partners/down/itprotect.svg'
import ts_solution from '../images/partners/down/Ts solution.svg'
import informguard from '../images/partners/down/informguard.png'


import partnersDownHover3 from '../images/partners/down/hover/item3.png'



import partnersUp1 from '../images/partners/up/item1.png'
import partnersUp3 from '../images/partners/up/item3.png'
import partnersUp5 from '../images/partners/up/item5.png'
import partnersUp6 from '../images/partners/up/item6.png'
import partnersUp10 from '../images/partners/up/item10.png'
import partnersUp12 from '../images/partners/up/item12.png'
import partnersUp13 from '../images/partners/up/item13.png'
import partnersUp14 from '../images/partners/up/item14.png'
import partnersUp16 from '../images/partners/up/item16.png'
import partnersUp17 from '../images/partners/up/item17.png'


import cemros from '../images/partners/up/cemros.jpg'
import dks from '../images/partners/up/DKC.png'
import ecovina from '../images/partners/up/econiva.png'
import faberlic from '../images/partners/up/Faberlic.png'
import remind from '../images/partners/up/Remind.png'
import rjd from '../images/partners/up/rjd.png'
import zenden from '../images/partners/up/Zenden.png'

import partnersUpHover1 from '../images/partners/up/hover/item1.png'
import partnersUpHover3 from '../images/partners/up/hover/item3.png'
import partnersUpHover5 from '../images/partners/up/hover/item5.png'
import partnersUpHover6 from '../images/partners/up/hover/item6.png'
import partnersUpHover10 from '../images/partners/up/hover/item10.png'
import partnersUpHover12 from '../images/partners/up/hover/item12.png'
import partnersUpHover13 from '../images/partners/up/hover/item13.png'
import partnersUpHover14 from '../images/partners/up/hover/item14.png'
import partnersUpHover16 from '../images/partners/up/hover/item16.png'
import partnersUpHover17 from '../images/partners/up/hover/item17.png'

export const partnersUp: IPartner[] = [
	{ id: 1, image: partnersUp1, hover: partnersUpHover1 },
	{ id: 3, image: partnersUp3, hover: partnersUpHover3 },
	{ id: 5, image: partnersUp5, hover: partnersUpHover5 },
	{ id: 6, image: partnersUp6, hover: partnersUpHover6 },
	{ id: 10, image: partnersUp10, hover: partnersUpHover10 },
	{ id: 12, image: partnersUp12, hover: partnersUpHover12 },
	{ id: 13, image: partnersUp13, hover: partnersUpHover13 },
	{ id: 14, image: partnersUp14, hover: partnersUpHover14 },
	{ id: 16, image: partnersUp16, hover: partnersUpHover16 },
	{ id: 17, image: partnersUp17, hover: partnersUpHover17 },
	{ id: 18, image: cemros },
	{ id: 19, image: dks },
	{ id: 20, image: ecovina },
	{ id: 21, image: faberlic },
	{ id: 22, image: remind },
	{ id: 23, image: rjd },
	{ id: 24, image: zenden },
]
export const partnersDown: IPartner[] = [
	{ id: 0, image: partnersDown1 },
	{ id: 7, image: a1 },
	{ id: 1, image: partnersDown2 },
	{ id: 9, image: ts_solution },
	{ id: 2, image: partnersDown3, hover: partnersDownHover3 },
	{ id: 8, image: itprotect },
	{ id: 6, image: partnersDown7 },
	{ id: 10, image: informguard },
]
